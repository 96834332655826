<div
  class="ticket-card"
  [class.ticket-card--extend]="type === ticketCardType.Extend"
  [class.ticket-card--loss]="ticket.is_won === false"
>
  <div class="ticket-card__content">
    <ng-container
      *ngIf="
        ticket.is_won === true && type === ticketCardType.Extend && ticket.image
      "
    >
      <img [src]="ticket.image" alt="" />
    </ng-container>

    <span *ngIf="ticket.is_won === true && type !== ticketCardType.Extend">
      <strong>{{ ticket.market_value | currency: 'GBP' }}</strong>
      Site Credit
    </span>

    <span *ngIf="ticket.is_won === false"> {{ ticket.ticket_number }}</span>
  </div>

  <div class="ticket-card__footer" *ngIf="ticket.is_won !== false">
    {{ ticket.ticket_number }}
  </div>
</div>

<a
  [routerLink]="['/product', raffle.slug]"
  class="raffle-card raffle-card--{{ version }}"
  [class.raffle-card__today]="
    drawTimeVersion === 'today' || drawTimeVersion === 'tomorrow'
  "
>
  <div class="raffle-card__image">
    <!--    <span class="raffle-card__label raffle-card__label_green" *ngIf="originalPrice">Sale!</span>-->
    <div class="raffle-card__image-red-cover" *ngIf="!raffle.is_open">
      <ng-container
        *ngIf="
          raffle.current_entries >= raffle.max_entries;
          else competitionClosed
        "
        >Sold out!</ng-container
      >
      <ng-template #competitionClosed>Closed</ng-template>
    </div>

    <img
      [ngSrc]="raffle.thumbnail.big"
      width="600"
      height="480"
      [alt]="raffle?.thumbnail?.attributes?.alt || 'Loading'"
      [attr.title]="
        raffle.thumbnail.attributes.title
          ? raffle.thumbnail.attributes.title
          : raffle.title
      "
    />
    <ng-container *ngIf="!raffle.is_without_draw && !isMixed">
      <div
        class="raffle-card__drawtime raffle-card__drawtime--urgent"
        *ngIf="drawTimeVersion === 'today'"
      >
        Draw Today {{ raffle.result_at | unifyDate | tzDate: 'ha' | lowercase }}
        {{ raffle.result_at | ukTimezone }}
      </div>
      <div
        class="raffle-card__drawtime raffle-card__drawtime--urgent"
        *ngIf="drawTimeVersion === 'tomorrow'"
      >
        Draw Tomorrow
        {{ raffle.result_at | unifyDate | tzDate: 'ha' | lowercase }}
        {{ raffle.result_at | ukTimezone }}
      </div>
      <div class="raffle-card__drawtime" *ngIf="drawTimeVersion === 'thisweek'">
        Draw {{ raffle.result_at | unifyDate | tzDate: 'EEEE' }}
        {{ raffle.result_at | unifyDate | tzDate: 'ha' | lowercase }}
        {{ raffle.result_at | ukTimezone }}
      </div>
      <div class="raffle-card__drawtime" *ngIf="drawTimeVersion === 'launched'"
        >Just launched</div
      >
    </ng-container>

    <div
      class="raffle-card__bottominfo"
      [class.raffle-card__bottominfo--grey]="bottomInfoStyle === 'grey'"
      [class.raffle-card__bottominfo--blue-border]="
        bottomInfoStyle === 'blue-border'
      "
      *ngIf="bottomInfoText"
      >{{ bottomInfoText }}</div
    >
  </div>

  <div *ngIf="isInstantWin" class="raffle-card__logo">
    <img
      src="/assets/images/iw-card-logo.png"
      alt="instant win card logo"
      *ngIf="isInstantWin"
    />
  </div>

  <div
    class="raffle-card__wrapper"
    [class.raffle-card__wrapper-margin]="!isInstantWin"
  >
    <h3 class="raffle-card__title">
      {{ raffle.title }}
    </h3>
    <div class="raffle-card__subtitle" *ngIf="raffle.subtitle">
      {{ raffle.subtitle }}
    </div>
    <div
      *ngIf="raffle.cash_alternative"
      class="raffle-card__subtitle raffle-card__cash-alternative"
    >
      Cash Alternative:
      {{ raffle.cash_alternative | currency: 'GBP' : 'symbol' : '1.0' }}
    </div>
  </div>
  <div class="raffle-card__price">
    <span class="raffle-card__price-old" *ngIf="originalPrice">{{
      originalPrice | currency: currency
    }}</span>
    <span class="raffle-card__price-new">{{ price | currency: currency }}</span>
  </div>

  <div
    class="raffle-card__slider"
    [class.raffle-card__slider--hidden]="raffle.is_cash || sliderValue === null"
  >
    <div class="raffle-card__sold"> sold: {{ sliderValue }} % </div>

    <div class="raffle-card__slider--track">
      <span
        class="raffle-card__slider--track-fill"
        [ngStyle]="{ width: sliderValue + '%' }"
      ></span>
    </div>
  </div>

  <div class="raffle-card__enter">
    <span>Enter now</span>
    <svg-icon
      class="raffle-card__enter-icon raffle-card__enter-icon--white"
      name="ticket-small"
    ></svg-icon>
    <svg-icon
      class="raffle-card__enter-icon raffle-card__enter-icon--blue"
      name="ticket-small-blue"
    ></svg-icon>
  </div>
</a>

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InstantWins, TicketCardType } from '@core/models/raffle.model';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class TicketCardComponent {
  @Input() ticket: InstantWins = null;
  @Input() type: TicketCardType = TicketCardType.Normal;

  ticketCardType = TicketCardType;
}

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Cart } from '@core/models/cart.model';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { Select } from '@ngxs/store';
import { CartState } from '@store/cart/cart.state';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagePopupService } from '@core/services/message-popup/message-popup.service';
import { SettingsState } from '@store/settings/settings.state';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showInstallPrompt = true;
  public alive$: Subject<void> = new Subject();
  public timeout: number;
  public isSubHeaderVisible: boolean;
  public isMobileMenuVisible: boolean;
  public cart: Cart | null;
  public appHomepageBannerText: string | null;
  public isNativeAppVisible = false;
  public isIosApp = false;
  public isAndroidApp = false;
  public isShort = false;
  public isActivePrompt = false;
  public isBudgetLeft = false;
  public remainingLimit = false;

  @Select(CartState.cart) cart$: Observable<Cart | null>;
  @Select(SettingsState.getAppBanner) appHomepageBanner$: Observable<string | null>;

  constructor(
    private window: Window,
    public messagePopupService: MessagePopupService,
    public dialog: MatDialog
  ) {
    this.appHomepageBanner$.pipe(takeUntil(this.alive$)).subscribe((text: string | null) => {
      this.appHomepageBannerText = text;
    });
  }

  ngOnInit(): void {
    this.isNativeAppVisible = GonativeService.isGonative();
    this.isIosApp = GonativeService.isIos();
    this.isAndroidApp = GonativeService.isAndroid();
    this.checkHeaderVisability();


    fromEvent(this.window, 'scroll').pipe(
      takeUntil(this.alive$)
    ).subscribe(() => {
      if (this.timeout) {
        this.window.cancelAnimationFrame(this.timeout);
      }

      this.timeout = this.window.requestAnimationFrame(() => {
        this.checkHeaderVisability();
      });

      this.isShort = window.scrollY > 150;
    });
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  checkHeaderVisability() {
    const headerPosition = window.pageYOffset;
    this.isSubHeaderVisible = headerPosition > 200;
  }

  handleMobileMenuOpen() {
    this.isMobileMenuVisible = true;
  }

  handleMobileMenuClose() {
    this.isMobileMenuVisible = false;
  }

  stopPropagation(e: MouseEvent) {
    e.stopPropagation();
  }

  goBack(event) {
    event.preventDefault();
    history.back();
  }

  handlePrompt(event) {
    this.isActivePrompt = event;
  }

}

import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { HomePageSlide } from '@core/models/homepage-slide.model';
import { HttpClient } from '@angular/common/http';
import { API_LIST } from '@core/services/web-api/api-list';
import { HeroSection } from '@core/models/hero-section.model';
import { RaffleSimple } from '@core/models/raffle.model';
import { share } from 'rxjs/operators';
import { ItemTab } from '@components/sticky-nav/sticky-nav.component';
import { MediaType } from '@core/models/media-info.model';
import { GonativeService } from '@core/services/gonative/gonative.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  static CACHED_HERO_SECTION: HeroSection | null = null;

  slides: HomePageSlide[] = [];
  winners: { url: string, alt: string }[] = [];
  competitionSections: CompetitionSection[] = [];
  isLoadingNavigation = true;
  rafflesNavItems: ItemTab[] = [];
  isNativeAppVisible = false;

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.initSlider();
    this.initCompetitions();
    this.initWinners();
    this.isNativeAppVisible = GonativeService.isGonative();
  }

  initSlider() {
    if (HomeComponent.CACHED_HERO_SECTION) {
      this.applyHeroSection(HomeComponent.CACHED_HERO_SECTION);
    } else {
      this.httpClient.get(API_LIST.getHeroSection('homepage')).subscribe((data: HeroSection) => {
        HomeComponent.CACHED_HERO_SECTION = data;
        this.applyHeroSection(HomeComponent.CACHED_HERO_SECTION);
      });
    }
  }

  applyHeroSection(data: HeroSection) {
    const slides: HomePageSlide[] = [];

    for (const box of data.boxes) {
      const slide = new HomePageSlide();
      slide.title = box.title;
      slide.line1 = box.line1;
      slide.line2 = box.line2;
      slide.sourceUrl = box.media_info.url;
      slide.poster = box.media_info.original;
      slide.type = box.media_info.type;
      slide.ctaUrl = box.url;
      slide.ctaTitle = 'Enter Now';
      slides.push(slide);
    }

    this.slides = slides;
  }

  initDefaultSlider() {
    this.slides = [
      {
        title: 'WIN AMAZING PRIZES TODAY!',
        line1: 'WIN AMAZING',
        line2: 'PRIZES TODAY!',
        sourceUrl: 'assets/images/7days/slider/moneybag.jpg',
        type: MediaType.Image,
        ctaUrl: '/all-competitions',
      }
    ];
  }

  initCompetitions() {
    this.addCompetitionSection(this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', [], 8, false, true, 0, 'upcoming', 'upcoming')
    ), 'Upcoming Competitions', 'Upcoming Competitions', 'Upcoming Competitions', 'upcoming-competitions');

    this.addCompetitionSection(this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', ['current-competitions'], 48, false, true, 0, 'date')
    ), 'Cars & Bikes', 'Cars & Bikes', ' Car & Bike Competitions', 'cars-competitions');

    this.addCompetitionSection(this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', ['cash-competitions'], 48, false, true, 0, 'date')
    ), 'Cash', 'Cash', 'Cash Competitions', 'cash-competitions');

    this.addCompetitionSection(this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', ['current-tech-competitions'], 48, false, true, 0, 'date')
    ), 'Tech & Luxury', 'Tech & Luxury', 'Tech & Luxury Competitions', 'tech-competitions');

    this.addCompetitionSection(this.httpClient.get(
      API_LIST.getRaffleDraws('GBP', ['instant-wins'], 48, false, true, 0, 'date')
    ), 'Instant Wins', 'Instant Wins', 'Instant Win Competitions', 'instant-wins');
  }

  addCompetitionSection(rafflesLoader$: Observable<any>, menuName: string, menuFullName: string, headingName: string, idAttr: string) {
    const section = new CompetitionSection();

    const loaderShared$ = rafflesLoader$.pipe(share());

    loaderShared$.subscribe((raffles: RaffleSimple[]) => {
      section.raffles = raffles;
      section.isAvailable = (raffles.length > 0);
      section.isLoading = false;
      this.refreshCompetitionSectionsOrder();
    }, () => {
      section.isAvailable = false;
      section.isLoading = false;
      this.refreshCompetitionSectionsOrder();
    });

    section.rafflesLoader$ = loaderShared$;
    section.menuName = menuName;
    section.menuFullName = menuFullName;
    section.headingName = headingName;
    section.idAttr = idAttr;

    this.competitionSections.push(section);
  }

  refreshCompetitionSectionsOrder() {
    let lastOdd = true;
    let isLoadingAnySection = false;

    for (const section of this.competitionSections) {
      if (section.isLoading) {
        isLoadingAnySection = true;
      }

      if (!section.isLoading && !section.isAvailable) {
        continue;
      }

      section.isOdd = lastOdd;
      lastOdd = !lastOdd;
    }

    this.isLoadingNavigation = isLoadingAnySection;

    if (!isLoadingAnySection) {
      this.buildRafflesNavItems();
    }
  }

  buildRafflesNavItems() {
    const items = [];

    this.competitionSections.map((section, index) => {
      if (section.isAvailable) {
        const itemTab = new ItemTab();
        itemTab.id = index;
        itemTab.idAttr = section.idAttr;
        itemTab.name = section.menuName;
        itemTab.fullName = section.menuFullName;

        items.push(itemTab);
      }
    });

    this.rafflesNavItems = items;
  }

  initWinners() {
    this.httpClient.get(API_LIST.getPreviousWinners()).subscribe((winnersRaw: any[]) => {
      const winners = winnersRaw.map((winner) => {
        return {
          url: winner.image_info.url,
          alt: '',
        };
      });

      this.winners = winners;
    });
  }
}

class CompetitionSection {
  rafflesLoader$: Observable<RaffleSimple[]> | null = null;
  raffles: RaffleSimple[] = [];
  menuName = 'Competitions';
  menuFullName = 'Competitions';
  headingName = 'Competitions';
  idAttr = '';
  isLoading = true;
  isAvailable = true;
  isOdd = false;
}

import { CartAddon } from '@core/models/cart.model';
import { FaqGroup } from '@core/models/faq.model';
import { MediaInfo } from '@core/models/media-info.model';
import { OrderItem } from '@core/models/order.model';

export class RaffleCompetitionsRequest {
  currency?: string;
  category?: string[];
  includeRunning?: boolean;
  includeFinished?: boolean;
  limit?: number;
  offset?: number;
  sortBy?: string;
}

export class RaffleCategory {
  slug: string;
  name: string;
  sort_by: string;
}

export class RaffleCategoryMetadata {
  showAllRaffles: boolean = false;
  slug: string;
  name: string;
  showSorting: boolean = false;
  containerFluid: boolean = false;
  theme: string;
  filter: string | null = null;
  sortBy: string | null = null;
}

export class RaffleCategoryMetadataIndexedList {
  [key: string]: RaffleCategoryMetadata;
}

export class PageRaffleList {
  page_data: PageRaffleListData;
  raffles: RaffleSimple[];
}

export class PageRaffleListData {
  id: number;
  title: string;
  slug: string;
  raffle_draws_at: string;
  theme: string;
  sort_value: string;
}

export class RaffleItemPrice {
  id: number;
  price: string;
  original_price_value: string | null;
  original_price_value_frontend: string | null;
  currency: string;
  currency_prepend: string;
  currency_append: string;
  title: string;
  tickets_quantity: number;
  original_price: CurrencyAmount | null;
  extra_units: number;
  credit_bonus: number;
}

export class CurrencyAmount {
  value: string;
  currency: string;
  currency_prepend: string;
  currency_append: string;
}

export const DrawAvailability = {
  AppOnly: 'app-only',
  BrowserOnly: 'browser-only'
};
export type DrawAvailabilityType = null | 'app-only' | 'browser-only';

export class RaffleSimple {
  id: number | null;
  slug: string;
  title: string;
  subtitle: string;
  end_at: string;
  start_at: string;
  result_at: string | null;
  prize_value: string | null;
  is_cash: boolean;
  cash_alternative: string | null;
  is_featured: boolean;
  is_without_draw: boolean;
  offers: RaffleItemPrice[];
  discount_tickets_min: number | null;
  discount_tickets_max: number | null;
  discount_start_at: string | null;
  discount_end_at: string | null;
  pricing_variant: string;
  offer_annotation: string | null;
  default_tickets: number;
  max_entries: number | null;
  current_entries: number | null;
  max_entrants: number | null;
  current_entrants: number | null;
  thumbnail: MediaInfo;
  video: MediaInfo;
  is_open: boolean;
  ticket_limit_per_user: number | null;
  ticket_limit_per_user_per_day: number | null;
  min_tickets_per_user: number | null;
  category_ids: string[] | null;
  administration_flag: boolean;
  purchase_available_type: DrawAvailabilityType;
  draw_method: 'auto' | 'live';
  instant_win_count: number;
}

export class Raffle extends RaffleSimple {
  description: string;
  item_photos: MediaInfo[];
  random_draw_scheduled_at: string | null;
  random_draw_at: string | null;
  faq_group: FaqGroup | null;
  question: string | null;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  finished_reason: string | null;
  popup_text: string | null;
  popup_raffle: RaffleDraw | null;
  popup_category: PopupRaffleCategory | null;
  prize_count: number;
  end_prize_name: string | null;
  end_prize_short_name: string;
  end_prize_image_info: MediaInfo | null;
  tickets_added_per_postcard_entry: number;
}

export class RaffleTicket {
  raffle_number: string;
  raffle_numeric: string;
  media_info: MediaInfo;
  market_value: string;
  flags: { [key: string]: string };
  raffleMap?: { [key: string]: number };
  product_details?: { [key: string]: string };
}

export class InstantWinGroup {
  id: number;
  available_instant_wins: number;
  number_of_prizes: number;
  prize_name: string;
  market_value: string;
  extra_cash: string | null;
  media_info: MediaInfo;
  description: string | null;
  ticket_prize_quantity: number;
}

export class InstantWins {
  is_available?: boolean;
  is_won?: boolean | null;
  prize_name: string | null;
  prize_type: string | null;
  ticket_number: string;
  ticket_numeric: number;
  market_value?: number;
  image?: string;
}

export class RaffleTicketsGroup {
  tid: string;
  raffle: RaffleDraw;
  type: string;
  raffle_info: RaffleSimple;
  available_offers: RaffleItemPrice[];
  tickets_quantity: number;
  live_tickets_quantity: number;
  created_at: string;
  planned_draw_end_at: string;
  instant_win_checked_at: string | null;
  is_draw_closed: boolean;
  tickets: RaffleTicket[];
  user_input1: string;
  user_input2: string;
  isShown?: boolean;
  raffle_available_for_user: boolean;
}

export class RaffleOffersAndAddons {
  offers: RaffleOffer[];
  addons: CartAddon[];
}

export class RaffleOffer {
  raffle_info: RaffleSimple;
  offer_info: RaffleItemPrice;
  order_item: OrderItem;
}

export enum RaffleItemType {
  bag = 'bag',
  bagCharity = 'bag-charity',
  event = 'event',
  eventCharity = 'event-charity'
}

export enum RaffleItemGameType {
  customDraw = 'CustomDraw'
}

export class ExtraBlock {
  id: number;
  icon: string;
  priority: number;
  primary_line: string;
  secondary_line: string;
  created_at: string;
}

export class ResultGroupItem {
  id: number;
  number: string;
  first_name: string;
  last_name: string;
}

export class RaffleDraw {
  id: number;
  slug: string;
  title: string;
  start_at: string;
  end_at: string;
  real_end_at: string;
  result_at: string;
  processed_end_at: string;
  method: string;
}

export class PopupRaffleCategory {
  slug: string;
  name: string;
}

export class RandomDrawResult {
  created_at: string;
  total_entry_count: number;
  download_export_link: string;
  result_winners: RandomDrawResultWinner[];
}

export class RandomDrawResultWinner {
  id?: number;
  created_at: string;
  rng_seed: number;
  winning_index: number;
  unix_time: number;
  winning_raffle_number: string;
  entries_number: number;
  raffle_title: string | null;
  raffle_prize_name: string | null;
}

export class RaffleTicketWithCreationIds extends RaffleTicket {
  creation_id1: string;
  creation_id2: string;
}

export class SimpleRaffleGroup {
  raffle_info: RaffleSimple;
  tickets: RaffleTicketWithCreationIds[];
  user_input1: string;
  user_input2: string;
}

export class SimpleRaffleAvailableGroup extends SimpleRaffleGroup {
  raffle_available_for_user: boolean;
}

export enum TicketCardType {
  Normal = 'normal',
  Extend = 'extend',
  Big = 'big'
}

export enum InstantWinTab {
  Win = 'win',
  Loss = 'loss'
}
